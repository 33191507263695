import React, { useState, useEffect } from "react";
import iconSrc from 'assets/images/desktop/pending_complete_icon.png';
import { Card, CardBody, Row, Col, Container, Progress } from "reactstrap";
import PropTypes from "prop-types";

const VidComplete = ({ canCheckStatus, progressValue }) => {

  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [showCheckStatusButton, setShowCheckStatusButton] = useState(false);

  // sometimes, even if the VID request passed, a socket event update may not be received
  // as a work-around, we enable the user to refresh the VID request result manually
  const manualCheck = () => {
    setRefreshInProgress(true);
    window.location.reload();
  }

  // Effect to delay the appearance of the button
  useEffect(() => {
    let timeout;
    if (progressValue === 100) {
      // Set a timeout of 3 seconds (3000 ms) before showing the button
      timeout = setTimeout(() => {
        setShowCheckStatusButton(true);
      }, 3000);
    } else {
      // If progressValue is not 100, hide the button
      setShowCheckStatusButton(false);
    }

    // Clear timeout if the component is unmounted or if progressValue changes
    return () => clearTimeout(timeout);
  }, [progressValue]);

  return <React.Fragment>
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="vid-column mt-4">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3" src={iconSrc}></img>
                <h5 className="mt-5 mb-4">Pending Review</h5>
                {progressValue ? <>
                  <div>Upload: {progressValue}%</div>
                  <Progress className="upload-progress-bar" color={progressValue == 100 ? "success" : "primary"} value={progressValue}></Progress>
                </> : ''}
                <div className="mt-4">Captured documents are uploading and our agents will review your documents.</div>
              </div>
            </CardBody>
          </Card>
          {(canCheckStatus || showCheckStatusButton) &&
            <Card>
              <button className={"btn btn-primary w-100" + (refreshInProgress ? ' disabled' : '')} onClick={manualCheck}>Check status</button>
            </Card>
          }
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

VidComplete.propTypes = {
  canCheckStatus: PropTypes.bool,
  progressValue: PropTypes.number,
};

export default VidComplete;
